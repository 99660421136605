import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  CommtracNodeHistory,
  CommtracNodeHistoryListQuery,
  CommtracNodeHistoryListResponse,
} from '../../../interfaces/CommtracNodeHistory';
import {getBooleanValue} from '../../../utils/boolean';
import {
  employeeMessageTypes,
  getCommtracNodeHistoryId,
} from '../../../utils/message-types';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import TruncatedText from '../../common/TruncantedText';
import CommtracNodeHistoryExportButton from '../../commtrac-nodes/CommtracNodeHistoryExportButton';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import MessageTypeSelect from '../../selectors/MessageTypeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: EmployeeTrackingReportsData;
  onUpdate?: (value?: EmployeeTrackingReportsData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'battery_voltage',
  'date',
  'message_type',
  'motion_sensor_value',
  'message',
  'answer',
  'commtrac_current_zone_name',
  'strongest_cn_name',
];

interface EmployeeTrackingReportsDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: string[];
  selectAll: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    message_type?: number[];
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
  };
}

export interface EmployeeTrackingReportsData {
  activeId?: number;
  mapLayers?: MapLayerId[];
  mapLevel?: number | null;
  openedItems: EmployeeTrackingReportsDataTab[];
}

export const getEmployeeTrackingReportsData =
  (): EmployeeTrackingReportsData => ({
    activeId: undefined,
    mapLayers: ['employee_history', 'street', 'mine'],
    mapLevel: null,
    openedItems: [],
  });

const getEmployeeTrackingReportsDataTab = (
  id: number
): EmployeeTrackingReportsDataTab => ({
  id,
  selectAll: true,
  params: {
    date_start: dayjs().format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  },
});

export const EmployeeTrackingReports = ({value, onUpdate}: Props) => {
  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config = useMemo(() => {
    const v = value ?? getEmployeeTrackingReportsData();
    return {
      ...v,
      mapLayers: v.mapLayers ?? ['employee_history', 'street', 'mine'],
      mapLevel: v.mapLevel ?? null,
    };
  }, [value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex] &&
          config.openedItems[openedItemIndex].params
          ? config.openedItems[openedItemIndex]
          : getEmployeeTrackingReportsDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const commtracNodes = useAppSelector(({assets}) =>
    assets.commtrac_nodes.filter((i) => i.type === 'miner')
  );

  const tabNames = useMemo(
    () =>
      config.openedItems.map(
        (o) => commtracNodes.find((i) => i.id === o.id)?.name
      ),
    [config.openedItems, commtracNodes]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<CommtracNodeHistoryListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<CommtracNodeHistoryListQuery | null>(
    () =>
      openedItem?.id
        ? {
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            message_type: openedItem.params?.message_type ?? [],
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
            activeId: openedItem.id,
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: CommtracNodeHistoryListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/commtrac-node/${config.activeId}/history`;
        const resp = await API.get<CommtracNodeHistoryListResponse>(endpoint, {
          params,
        });
        setFetchedData(resp.data);
        // collect all IDs
        if (openedItem && openedItem.selectAll) {
          openedItem.selectedIds = resp.data.items.map((el) =>
            getCommtracNodeHistoryId(el)
          );
        }
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/

  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<CommtracNodeHistory>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(getCommtracNodeHistoryId(row))}
          onChange={() => toggleSelectItem(getCommtracNodeHistoryId(row))}
        />
      ),
    },
    {
      field: 'battery_voltage',
      renderHeader: () => (
        <Tooltip title="Battery Level">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: false,
      hideable: false,
      width: 50,
      valueGetter: ({row}) =>
        row.battery_voltage ? row.battery_voltage / 10 : null,
      renderCell: ({value}) => {
        if (value === null) {
          return <QuestionMarkIcon fontSize="small" />;
        }
        return <>{value}</>;
      },
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => row.timestamp,
      renderCell: ({row, value}) => {
        return (
          <TruncatedText
            color={getBooleanValue(row.alarm, 0, 1) ? 'error.main' : undefined}
            fontWeight={getBooleanValue(row.alarm, 0, 1) ? 'bold' : undefined}
          >
            {value?.toString()}
          </TruncatedText>
        );
      },
    },
    {
      field: 'message_type',
      headerName: 'Message Type',
      sortable: true,
      renderCell: ({row, value}) => {
        return (
          <TruncatedText
            color={getBooleanValue(row.alarm, 0, 1) ? 'error.main' : undefined}
            fontWeight={getBooleanValue(row.alarm, 0, 1) ? 'bold' : undefined}
          >
            {value?.toString()}
          </TruncatedText>
        );
      },
    },
    {
      field: 'motion_sensor_value',
      headerName: 'Motion State',
      sortable: true,
    },
    {
      field: 'message',
      headerName: 'Message',
      sortable: true,
    },
    {
      field: 'message_id',
      headerName: 'Message ID',
      sortable: true,
    },
    {
      field: 'answer',
      headerName: 'Status',
      sortable: true,
      valueGetter: ({row}) => row.status,
    },
    {
      field: 'commtrac_current_zone_name',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) => row.section,
    },
    {
      field: 'strongest_cn_name',
      headerName: 'Strongest Node',
      sortable: true,
    },
    {
      field: 'pos_lon',
      headerName: 'Longitude',
      sortable: true,
      valueGetter: ({row}) => row.longitude,
    },
    {
      field: 'pos_lat',
      headerName: 'Latitude',
      sortable: true,
      valueGetter: ({row}) => row.latitude,
    },
    {
      field: 'beacon_data',
      headerName: 'Beacon Data',
      sortable: true,
    },
  ];

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = openedItem?.selectedIds ?? [];

  const selectedRows = useMemo(
    () =>
      rows.filter((i) => selectedItems?.includes(getCommtracNodeHistoryId(i))),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i) => getCommtracNodeHistoryId(i)) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  /************/
  /* map data */
  /************/
  const mapData = useMemo(() => {
    if (fetchedData?.items && selectedItems.length) {
      return fetchedData?.items.filter((i) =>
        selectedItems.includes(getCommtracNodeHistoryId(i))
      );
    }
    return [];
  }, [fetchedData, selectedItems, selectedItems]);

  return (
    <>
      <DashboardPanelTitleSlot>
        Employee Tracking Reports
      </DashboardPanelTitleSlot>

      <ResizableColumns
        left={
          <Map
            panel="employee_tracking_report"
            employeeHistoryData={mapData}
            selectedLevel={config.mapLevel}
            selectedMapLayers={config.mapLayers}
            availableMapLayers={[
              'street',
              'mine',
              'employee_history'
            ]}
            onSelectMapLayers={(v) => {
              onUpdate?.(
                update(config, {
                  mapLayers: {
                    $set: v,
                  },
                })
              );
            }}
            onSelectLevel={(v) => {
              onUpdate?.(
                update(config, {
                  mapLevel: {
                    $set: v,
                  },
                })
              );
            }}
          />
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            py={1.5}
            bgcolor={(theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
            }
          >
            {isOpenAwayFromConnectView && (
              <Box px={1.5}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button variant="outlined" {...bindTrigger(popupState)}>
                        Select an Object
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {commtracNodes?.map((it, key: number) => (
                          <MenuItem
                            key={key}
                            onClick={() => {
                              onUpdate?.(
                                update(value, {
                                  activeId: {
                                    $set: it.id ?? undefined,
                                  },
                                  openedItems: {
                                    $set: [
                                      ...config.openedItems,
                                      {
                                        id: it.id ?? -1,
                                      } as EmployeeTrackingReportsDataTab,
                                    ],
                                  },
                                })
                              );
                              popupState.close();
                            }}
                          >
                            {it.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {config.openedItems.length ? (
              <Box px={2}>
                <Tabs
                  value={openedItem?.id}
                  variant="scrollable"
                  onChange={(_event, v) => {
                    if (v) {
                      onUpdate?.(
                        update(config, {
                          activeId: {
                            $set: v,
                          },
                        })
                      );
                    }
                  }}
                >
                  {config.openedItems.map((i, idx) => (
                    <Tab
                      key={i.id}
                      value={i.id}
                      label={
                        <Box display="flex" alignItems="center" gap={1}>
                          <Box>{tabNames?.[idx] ?? 'Undefined'}</Box>
                          <CloseIcon
                            fontSize="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              onUpdate?.(
                                update(value, {
                                  activeId: {
                                    $set:
                                      config.activeId === i.id
                                        ? undefined
                                        : config.activeId,
                                  },
                                  openedItems: {
                                    $set:
                                      config.openedItems.filter(
                                        (o) => o.id && o.id !== i.id
                                      ) ?? [],
                                  },
                                })
                              );
                            }}
                          />
                        </Box>
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <Box minWidth={400} px={1.5}>
                <Alert severity="warning">No Employees Opened</Alert>
              </Box>
            )}

            <Box display="flex" flexDirection="column" px={1.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <Box minWidth={400}>
                    <DateRangeSelect
                      value={
                        openedItem
                          ? [
                              dayjs(openedItem.params?.date_start).toDate(),
                              dayjs(openedItem.params?.date_end).toDate(),
                            ]
                          : undefined
                      }
                      size="small"
                      disabled={!openedItem}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    selectAll: true,
                                    params: {
                                      ...openedItem.params,
                                      date_start: v?.[0]
                                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                        : undefined,
                                      date_end: v?.[0]
                                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                        : undefined,
                                    },
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />
                  </Box>

                  <MessageTypeSelect
                    value={openedItem?.params?.message_type}
                    size="small"
                    disabled={!openedItem}
                    options={employeeMessageTypes}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  selectAll: true,
                                  params: {
                                    ...openedItem.params,
                                    message_type: v,
                                  },
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />
                </Box>

                <Box display="flex">
                  <ButtonGroup disabled={!openedItem}>
                    <Button size="small">
                      <CommtracNodeHistoryExportButton
                        exportType="employee"
                        componentProps={{
                          ...value,
                          id: value?.activeId,
                          date_start: openedItem?.params?.date_start,
                          date_end: openedItem?.params?.date_end,
                        }}
                      />
                    </Button>

                    <Button
                      size="small"
                      onClick={() => params && fetchData(params)}
                    >
                      <RefreshIcon />
                    </Button>

                    <AutoRefreshSelect
                      value={openedItem?.refreshInterval ?? null}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    refreshInterval: v,
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />

                    <Button
                      size="small"
                      onClick={() => dataGridRef.current?.printTable()}
                    >
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <Backdrop
            open={fetchedInProgress}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors.map((error, idx) => (
            <Alert
              key={idx}
              severity="error"
              onClose={() => params && fetchData(params)}
            >
              {error}
            </Alert>
          ))}

          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            loading={fetchedInProgress}
            shownFields={shownFields}
            pagination
            paginationMode="server"
            size="small"
            sortBy={
              params?.order
                ? {
                    field: params?.order,
                    dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                  }
                : null
            }
            sortingMode="server"
            page={params?.page}
            pageSize={params?.limit}
            rowCount={fetchedData?.count}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            onPageChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onPageSizeChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onSort={(v) => {
              if (v && openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          params: {
                            ...openedItem.params,
                            order: v.field,
                            dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onShownFieldsChange={setShownFields}
          />
        </Box>
      </ResizableColumns>
    </>
  );
};
